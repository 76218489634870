import React, {useRef, useState} from "react";
import {QRCodeCanvas} from 'qrcode.react';
import "./QRCode.css";


function downloadStringAsFile(data, filename) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
}


const QRCodeComponent = ({value, hint, size, bg_color, fg_color, download, ...props}) => {
  const canvasRef = React.useRef(null);
  const qrcode_canvas = <QRCodeCanvas value={value}
                                      title={hint}
                                      size={size}
                                      bgColor={bg_color}
                                      fgColor={fg_color}
                                      level="L"
                                      ref={canvasRef}
  />

  function onClick() {
    const data = canvasRef.current.toDataURL();
    downloadStringAsFile(data, 'qrcode.png');
  }

  if (download) {
    return <div className={"qr-container"} onClick={onClick}>
      <div className={"qr-code-div"}>
        {qrcode_canvas}
      </div>
      <div className={"overlay"}>
        <img src={process.env.PUBLIC_URL + "/download_icon.png"} alt="download" className={"download-icon"}/>
      </div>
    </div>
  } else {
    return qrcode_canvas;
  }
}

export default QRCodeComponent;
