import React, {useContext} from "react";
import {Redirect} from "react-router-dom";
import {Container, Button} from "react-bootstrap";
import {userContext} from "../stores/userContext";
import GoogleLogin from "react-google-login";
import {reactUrl} from "../stores/apiStore";
import {COLORS} from "../constants";
import LoadingAlert from "./LoadingAlert";
import HebrewLabel from "./HebrewLabel";

const LoginComponent = () => {
  const {user, logoutUser} = useContext(userContext);
  const isLoggedOut = Object.keys(user).length === 0;
  const loginMessage = isLoggedOut ? 'התחבר/י' : 'התנתק/י'

  const loggedInButton =
    <Button onClick={logoutUser} variant="outline-light" style={{backgroundColor: COLORS.TALPIOT_CYAN}}>
      {loginMessage}
    </Button>

    if (isLoggedOut) {
      // add a cookie containing the current url and make it expire in 15 minutes
      // the redirect cookie should contain url and url params
      const url = new URL(window.location.href)
      const redirect_url = url.pathname + url.search
      const redirect_url_escaped = encodeURIComponent(redirect_url)
      document.cookie = `redirect=${redirect_url_escaped};max-age=900;path=/`;
    }

    const loggedOutButton = <GoogleLogin
        clientId="330244991572-iimj6mtoao2motr1c05oklic54oo50vb.apps.googleusercontent.com"
        buttonText={loginMessage}
        uxMode='redirect'
        redirectUri={reactUrl + '/login'}
        cookiePolicy={'single_host_origin'}
        style={{width: 'fit-content'}}
    />
    return <Container style={{width: 'fit-content', padding: '0 5vh'}} dir="ltr">
        {isLoggedOut ? loggedOutButton : loggedInButton}
    </Container>
};

const LoginParseRedirectFromGoogle = ({user, loginUser, isDev}) => {
  // if there is a redirect cookie, get it
  var redirect = null;
  var cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].includes('redirect')) {
      redirect = cookies[i].split('=')[1];
      redirect = decodeURIComponent(redirect);
      break;
    }
  }

  console.log("redirect to: ", redirect);
  if (Object.keys(user).length === 0) { // if user doesn't exist
    let afterhash = window.location.hash.substr(1);
    let key = afterhash.split("id_token=")[1].split("&login_hint=")[0];

    loginUser({tokenId: key, isDev: isDev});

    return <Container>
      <h1>מתחבר לשרת</h1>
      <Container style={{width: '50%'}}>
        {LoadingAlert}
      </Container>
      <HebrewLabel color="#888">לא אמור לקחת יותר מ5 שניות</HebrewLabel>
    </Container>;

  } else {
    if (redirect !== null) {
      // delete the cookie
      document.cookie = 'redirect=;max-age=0;path=/';
      return <Redirect to={redirect}/>;
    } else {
      if (isDev) {
        return <Redirect to="/react-dev"/>;
      } else {
        return <Redirect to="/react"/>;
      }
    }
  }
};

export {LoginParseRedirectFromGoogle};

export default LoginComponent;
